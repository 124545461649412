<ion-content class="ion-no-padding">
  <ion-list>
    <ion-button *ngFor="let c of contrastNames; trackBy: trackByFn" (click)="change(c.code)"  (keyup.enter)="change(c.code)" size="small" expand="block" tabindex="0">
      <ion-label>{{ c.label }}</ion-label>
    </ion-button>
    <ion-button (click)="upScale($event)" (keyup.enter)="upScale($event)" size="small" expand="block" tabindex="0">
      <ion-label>++</ion-label>
    </ion-button>
    <ion-button (click)="downScale($event)" (keyup.enter)="downScale($event)" size="small" expand="block" tabindex="0">
      <ion-label>--</ion-label>
    </ion-button>
     <ion-button (click)="scaleTo(10)" (keyup.enter)="scaleTo(10)" size="small" expand="block" tabindex="0">
       <ion-label>10</ion-label>
     </ion-button>
     <ion-button (click)="scaleTo(14)" (keyup.enter)="scaleTo(14)" size="small" expand="block" tabindex="0">
       <ion-label>14</ion-label>
     </ion-button>
     <ion-button (click)="scaleTo(18)" (keyup.enter)="scaleTo(18)" size="small" expand="block" tabindex="0">
       <ion-label>18</ion-label>
     </ion-button>
     <ion-button (click)="scaleTo(22)" (keyup.enter)="scaleTo(22)" size="small" expand="block" tabindex="0">
       <ion-label>22</ion-label>
     </ion-button>
     <ion-button (click)="scaleTo(26)" (keyup.enter)="scaleTo(26)" size="small" expand="block" tabindex="0">
       <ion-label>26</ion-label>
     </ion-button>
   </ion-list>
</ion-content>


