<ion-content class="ion-no-padding c-switcher">
  <ion-list>
    <button (click)="change('bw-pos')"  (keyup.enter)="change('bw-pos')" tabindex="0" class="ico-bw-pos">
      <!--      <ion-label>{{ c.label }}</ion-label>-->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <g class="ico-bg-bw-pos" data-name="Ebene 2">
          <rect width="200" height="200"/>
        </g>
        <g class="ico-fg-bw-pos" data-name="Ebene 1">
          <path d="M83.3,111.65c0,6.93-2.2,12.3-6.6,16.1-4.73,4.13-11.7,6.2-20.9,6.2-5.8,0-11.12-1.13-15.95-3.4-4.83-2.27-8.58-5.37-11.25-9.3l10.1-9c4.6,5.33,10.57,8,17.9,8,8.13,0,12.2-2.53,12.2-7.6,0-2.33-1-4.13-3-5.4-2-1.27-6.2-2.63-12.6-4.1-7.33-1.67-12.6-3.8-15.8-6.4-4.07-3.27-6.1-8.1-6.1-14.5s2.43-11.2,7.3-15c4.67-3.67,10.57-5.5,17.7-5.5,10,0,18.5,3.67,25.5,11l-10.1,9.8c-4.67-4.8-9.97-7.2-15.9-7.2-6.67,0-10,2.1-10,6.3,0,2.13.97,3.77,2.9,4.9,1.73,1,5.47,2.17,11.2,3.5,7.27,1.73,12.57,3.77,15.9,6.1,5,3.54,7.5,8.7,7.5,15.5Z"/>
          <path d="M172.1,62.85l-14.6,70h-13.7l-8.6-36c-1.07-4.33-1.87-8.33-2.4-12-.4,3.27-1.17,7.27-2.3,12l-8.8,36h-13.1l-14.6-70h14.6l6.1,34.1c.8,4.53,1.37,8.37,1.7,11.5.6-3.6,1.4-7.43,2.4-11.5l8.2-34.1h12.2l8.3,34.1c.93,3.93,1.73,7.77,2.4,11.5.33-3.13.9-6.97,1.7-11.5l6.1-34.1h14.4Z"/>
        </g>
      </svg>
    </button>
    <button (click)="change('bw-neg')"  (keyup.enter)="change('bw-neg')" tabindex="0" class="ico-bw-neg">
      <!--      <ion-label>{{ c.label }}</ion-label>-->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <g class="ico-bg-bw-neg" data-name="Ebene 2">
          <rect width="200" height="200"/>
        </g>
        <g class="ico-fg-bw-neg" data-name="Ebene 1">
          <path d="M106,62.85l-14.6,70h-13.7l-8.6-36c-1.07-4.33-1.87-8.33-2.4-12-.4,3.27-1.17,7.27-2.3,12l-8.8,36h-13.1l-14.6-70h14.6l6.1,34.1c.8,4.53,1.37,8.37,1.7,11.5.6-3.6,1.4-7.43,2.4-11.5l8.2-34.1h12.2l8.3,34.1c.93,3.93,1.73,7.77,2.4,11.5.33-3.13.9-6.97,1.7-11.5l6.1-34.1h14.4Z"/>
          <path d="M171.4,111.65c0,6.93-2.2,12.3-6.6,16.1-4.73,4.13-11.7,6.2-20.9,6.2-5.8,0-11.12-1.13-15.95-3.4-4.83-2.27-8.58-5.37-11.25-9.3l10.1-9c4.6,5.33,10.57,8,17.9,8,8.13,0,12.2-2.53,12.2-7.6,0-2.33-1-4.13-3-5.4-2-1.27-6.2-2.63-12.6-4.1-7.33-1.67-12.6-3.8-15.8-6.4-4.07-3.27-6.1-8.1-6.1-14.5s2.43-11.2,7.3-15c4.67-3.67,10.57-5.5,17.7-5.5,10,0,18.5,3.67,25.5,11l-10.1,9.8c-4.67-4.8-9.97-7.2-15.9-7.2-6.67,0-10,2.1-10,6.3,0,2.13.96,3.77,2.9,4.9,1.73,1,5.47,2.17,11.2,3.5,7.27,1.73,12.57,3.77,15.9,6.1,5,3.53,7.5,8.7,7.5,15.5Z"/>
        </g>
      </svg>
    </button>
    <button (click)="change('by-pos')"  (keyup.enter)="change('by-pos')" tabindex="0" class="ico-by-pos">
      <!--      <ion-label>{{ c.label }}</ion-label>-->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <g class="ico-bg-by-pos" data-name="Ebene 2">
          <rect width="200" height="200"/>
        </g>
        <g class="ico-fg-by-pos" data-name="Ebene 1">
          <path d="M92.45,111.65c0,6.93-2.2,12.3-6.6,16.1-4.73,4.13-11.7,6.2-20.9,6.2-5.8,0-11.12-1.13-15.95-3.4-4.83-2.27-8.58-5.37-11.25-9.3l10.1-9c4.6,5.33,10.57,8,17.9,8,8.13,0,12.2-2.53,12.2-7.6,0-2.33-1-4.13-3-5.4-2-1.27-6.2-2.63-12.6-4.1-7.33-1.67-12.6-3.8-15.8-6.4-4.07-3.27-6.1-8.1-6.1-14.5s2.43-11.2,7.3-15c4.67-3.67,10.57-5.5,17.7-5.5,10,0,18.5,3.67,25.5,11l-10.1,9.8c-4.67-4.8-9.97-7.2-15.9-7.2-6.67,0-10,2.1-10,6.3,0,2.13.97,3.77,2.9,4.9,1.73,1,5.47,2.17,11.2,3.5,7.27,1.73,12.57,3.77,15.9,6.1,5,3.53,7.5,8.7,7.5,15.5Z"/>
          <path d="M162.25,105.75c0,8.8-2.67,15.73-8,20.8-5.07,4.93-11.77,7.4-20.1,7.4-9.2,0-16.6-3.27-22.2-9.8-5.6-6.53-8.4-15.3-8.4-26.3s2.9-19.35,8.7-26.05c5.8-6.7,13.27-10.05,22.4-10.05,12.67,0,21.5,5.83,26.5,17.5l-13.3,5.5c-1.47-3-3.07-5.17-4.8-6.5-2.2-1.73-5.07-2.6-8.6-2.6-5.13,0-9.17,2.03-12.1,6.1-2.93,4.07-4.4,9.43-4.4,16.1s1.47,11.93,4.4,16c3,4.27,7.07,6.4,12.2,6.4,8.2,0,12.77-3.53,13.7-10.6h-11.1v-13.1h25.1v9.2Z"/>
        </g>
      </svg>
    </button>
    <button (click)="change('by-neg')"  (keyup.enter)="change('by-neg')" tabindex="0" class="ico-by-neg">
      <!--      <ion-label>{{ c.label }}</ion-label>-->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <g class="ico-bg-by-neg" data-name="Ebene 2">
          <rect width="200" height="200"/>
        </g>
        <g class="ico-fg-by-neg" data-name="Ebene 1">
          <path d="M96.15,105.75c0,8.8-2.67,15.73-8,20.8-5.07,4.93-11.77,7.4-20.1,7.4-9.2,0-16.6-3.27-22.2-9.8-5.6-6.53-8.4-15.3-8.4-26.3s2.9-19.35,8.7-26.05c5.8-6.7,13.27-10.05,22.4-10.05,12.67,0,21.5,5.83,26.5,17.5l-13.3,5.5c-1.47-3-3.07-5.17-4.8-6.5-2.2-1.73-5.07-2.6-8.6-2.6-5.13,0-9.17,2.03-12.1,6.1-2.93,4.07-4.4,9.43-4.4,16.1s1.47,11.93,4.4,16c3,4.27,7.07,6.4,12.2,6.4,8.2,0,12.77-3.53,13.7-10.6h-11.1v-13.1h25.1v9.2Z"/>
          <path d="M162.25,111.65c0,6.93-2.2,12.3-6.6,16.1-4.73,4.13-11.7,6.2-20.9,6.2-5.8,0-11.12-1.13-15.95-3.4-4.83-2.27-8.58-5.37-11.25-9.3l10.1-9c4.6,5.33,10.57,8,17.9,8,8.13,0,12.2-2.53,12.2-7.6,0-2.33-1-4.13-3-5.4-2-1.27-6.2-2.63-12.6-4.1-7.33-1.67-12.6-3.8-15.8-6.4-4.07-3.27-6.1-8.1-6.1-14.5s2.43-11.2,7.3-15c4.67-3.67,10.57-5.5,17.7-5.5,10,0,18.5,3.67,25.5,11l-10.1,9.8c-4.67-4.8-9.97-7.2-15.9-7.2-6.67,0-10,2.1-10,6.3,0,2.13.96,3.77,2.9,4.9,1.73,1,5.47,2.17,11.2,3.5,7.27,1.73,12.57,3.77,15.9,6.1,5,3.53,7.5,8.7,7.5,15.5Z"/>
        </g>
      </svg>
    </button>

    <button *ngFor="let c of contrastNames; trackBy: trackByFn" (click)="change(c.code)"  (keyup.enter)="change(c.code)" tabindex="0" class="ico-{{c.code}}">
<!--      <ion-label>{{ c.label }}</ion-label>-->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <g class="ico-bg-{{c.code}}" data-name="Ebene 2">
          <rect width="200" height="200"/>
        </g>
        <g class="ico-fg-{{c.code}}" data-name="Ebene 1">
          <path d="M71.06,40.74h59.76v17.57h-40.03v23.47h36.43v17.57h-36.43v24.62h40.75v17.57h-60.48V40.74Z"/>
        </g>
      </svg>
    </button>
    <button (click)="upScale($event)" (keyup.enter)="upScale($event)" tabindex="0">
<!--      <ion-label>++</ion-label>-->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <g class="ico-bg-current" data-name="Ebene 2">
          <rect width="200" height="200"/>
        </g>
        <g class="ico-fg-current" data-name="Ebene 3">
          <path d="M68.25,85.1h23.33v-24.34h16.85v24.34h23.47v15.84h-23.47v24.62h-16.85v-24.62h-23.33v-15.84Z"/>
        </g>
      </svg>
    </button>
    <button (click)="downScale($event)" (keyup.enter)="downScale($event)" tabindex="0">
<!--      <ion-label>&#45;&#45;</ion-label>-->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <g class="ico-bg-current" data-name="Ebene 2">
          <rect width="200" height="200"/>
        </g>
        <g class="ico-fg-current" data-name="Ebene 4">
          <path d="M85.82,91.86h36.14v16.56h-36.14v-16.56Z"/>
        </g>
      </svg>
    </button>
     <button (click)="scaleTo(10)" (keyup.enter)="scaleTo(10)" tabindex="0">
<!--       <ion-label>10</ion-label>-->
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
         <g class="ico-bg-current" data-name="Ebene 2">
           <rect width="200" height="200"/>
         </g>
         <g class="ico-fg-current" data-name="Ebene 7">
           <path d="M82,70h35.57v10.46h-23.83v13.97h21.69v10.46h-21.69v14.66h24.26v10.46h-36v-60Z"/>
         </g>
       </svg>
     </button>
     <button (click)="scaleTo(14)" (keyup.enter)="scaleTo(14)" tabindex="0">
<!--       <ion-label>14</ion-label>-->
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
         <g class="ico-bg-current" data-name="Ebene 2">
           <rect width="200" height="200"/>
         </g>
         <g class="ico-fg-current" data-name="Ebene 1">
           <path d="M79,65h41.5v12.2h-27.8v16.3h25.3v12.2h-25.3v17.1h28.3v12.2h-42v-70Z"/>
         </g>
       </svg>
     </button>
     <button (click)="scaleTo(18)" (keyup.enter)="scaleTo(18)" tabindex="0">
<!--       <ion-label>18</ion-label>-->
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
         <g class="ico-bg-current" data-name="Ebene 2">
           <rect width="200" height="200"/>
         </g>
         <g class="ico-fg-current" data-name="Ebene 6">
           <path d="M76,60h47.43v13.94h-31.77v18.63h28.91v13.94h-28.91v19.54h32.34v13.94h-48V60Z"/>
         </g>
       </svg>
     </button>
     <button (click)="scaleTo(22)" (keyup.enter)="scaleTo(22)" tabindex="0">
<!--       <ion-label>22</ion-label>-->
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
         <g class="ico-bg-current" data-name="Ebene 2">
           <rect width="200" height="200"/>
         </g>
         <g class="ico-fg-current" data-name="Ebene 8">
           <path d="M73,55h53.36v15.69h-35.74v20.96h32.53v15.69h-32.53v21.99h36.39v15.69h-54V55Z"/>
         </g>
       </svg>
     </button>
     <button (click)="scaleTo(26)" (keyup.enter)="scaleTo(26)" tabindex="0">
<!--       <ion-label>26</ion-label>-->
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
         <g class="ico-bg-current" data-name="Ebene 2">
           <rect width="200" height="200"/>
         </g>
         <g class="ico-fg-current" data-name="Ebene 9">
           <path d="M70,50h59.29v17.43h-39.71v23.29h36.14v17.43h-36.14v24.43h40.43v17.43h-60V50Z"/>
         </g>
       </svg>
     </button>
   </ion-list>
</ion-content>


