import {Component, EventEmitter, OnInit, Output} from "@angular/core";

@Component({
  selector: 'app-break-counter',
  templateUrl: './break-counter.component.html',
  styleUrls: ['./break-counter.component.scss'],
  standalone: false
})
export class BreakCounterComponent implements OnInit {
  exportCount = 0;
  userPaused = false;
  mm = 0;
  ss = 0;
  ms = 0;
  isRunning = false;
  timerId = 0;
  activeClass = '';

  constructor() {
  }
  ngOnInit() {
  }
  @Output() userPausedChange = new EventEmitter<boolean>();
  @Output() breakCounter = new EventEmitter();
  clickHandler() {
    if (!this.isRunning) {
      this.activeClass = 'active';
      this.postUserPaused();
      // Stop => Running
      // @ts-ignore
      this.timerId = setInterval(() => {
        this.ms++;
        this.exportCount++;
        if (this.ms >= 100) {
          this.ss++;
          this.ms = 0;
        }
        if (this.ss >= 60) {
          this.mm++;
          this.ss = 0
        }
      }, 10);
    } else {
      this.activeClass = '';
      clearInterval(this.timerId);
      this.postUserPaused();
      this.postBreakCounter();
    }
    this.isRunning = !this.isRunning;
  }

  format(num: number) {
    return (num + '').length === 1 ? '0' + num : num + '';
  }

  postUserPaused() {
    this.userPaused = !this.userPaused;
    this.userPausedChange.emit(this.userPaused);
  }
  postBreakCounter() {
    this.breakCounter.emit(this.exportCount);
  }
}
