<div>
  <h1 class="form-heading">{{ stepItems[activeStepIndex].label }}</h1>
  <ng-container
    *ngFor="let form of masterForm; let i = index; trackBy: trackByFn">
    <form
      action=""
      [formGroup]="masterForm[i]"
      *ngIf="i === activeStepIndex || masterForm.length === 1">
      <div class="">
        <div class="form-container">
          <div
            *ngFor="let field of formFields[i]; trackBy: trackByFn"
            [ngSwitch]="currentFormContent[i][field].type">
            <div class="full-width">

              <ng-container *ngSwitchCase="'audio'">

              </ng-container>

              <ng-container *ngSwitchCase="'textarea'">
                <div class="form-group">
                  <p id="ra{{field}}">
                    {{ currentFormContent[i][field].title }}

                  </p>
                  <p>
                    <button (click)="readText(field)" (keyup.enter)="readText(field)" aria-label="Wiedergabe">
                      <ion-icon name="play-circle"></ion-icon>
                    </button>
                  </p>
                  <div class="input-group">
                    <textarea
                      [id]="field"
                      [formControlName]="field"></textarea>
                    <button (pointerdown)="startRecording(field, $event)" appDisableRightClick>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-record-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                      </svg>
                    </button>
                    <button aria-label="Aufnahme" class="btn-record br{{field}}" (pointerdown)="stopRecording(field)" appDisableRightClick>
                      <ion-icon name="stop-circle"></ion-icon>
                    </button>
                    <button (click)="readInput(field)" aria-label="Wiedergabe">
                      <ion-icon name="play-circle"></ion-icon>
                    </button>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'textfield'">
                <div class="form-group">
                  <p id="ra{{field}}"> {{ currentFormContent[i][field].title }}</p>
                  <p>
                    <button (click)="readText(field)" (keyup.enter)="readText(field)" aria-label="Wiedergabe">
                      <ion-icon name="play-circle"></ion-icon>
                    </button>
                  </p>
                  <div class="input-group">
                    <input type="text" [id]="field" [formControlName]="field" >
                    <button (pointerdown)="startRecording(field, $event)" appDisableRightClick class="btn-record">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-record-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                        <path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                      </svg>
                    </button>
                    <button aria-label="Aufnahme" class="btn-record br{{field}}" (pointerdown)="stopRecording(field)" appDisableRightClick>
                      <ion-icon name="stop-circle"></ion-icon>
                    </button>
                    <button (click)="readInput(field)" aria-label="Wiedergabe">
                      <ion-icon name="play-circle"></ion-icon>
                    </button>
                  </div>
                </div>

              </ng-container>

              <ng-container *ngSwitchCase="'select'">
                <select [formControlName]="field" [id]="field">
                  <option value="">{{ currentFormContent[i][field].title }}</option>
                  <option
                    *ngFor="let option of currentFormContent[i][field].options; trackBy: trackByFn"
                    [value]="option.name"
                  >{{ option.name }}</option>
                </select>
              </ng-container>

              <ng-container *ngSwitchCase="'range'">
                <p>{{ currentFormContent[i][field].title }}</p>
                <div class="form-group">
                <input [id]="field" [formControlName]="field" type="text" readonly>
                </div>
                <input (change)="controlForRange($event, currentFormContent[i][field], field)" type="range" list="range" step="{{currentFormContent[i][field].step}}" min="{{currentFormContent[i][field].min}}" max="{{currentFormContent[i][field].max}}">
                  <datalist id="range">
                    <option
                      *ngFor="let option of currentFormContent[i][field].options; trackBy: trackByFn"
                      [ngValue]="option.number + ' ' + option.name"
                      [value]="option.number"
                    > | </option>

                  </datalist>

              </ng-container>

              <ng-container *ngSwitchCase="'selectGroup'">
                <p>{{ currentFormContent[i][field].title }}</p>
                <select [formControlName]="field" [id]="field" (change)="controlRequiredForGroupBySelect($event, currentFormContent[i][field].group, currentFormContent[i][field].notValue)">
                  <option value="">{{ currentFormContent[i][field].title }}</option>
                  <option
                    *ngFor="let option of currentFormContent[i][field].options; trackBy: trackByFn"
                    [value]="option.name"
                  >{{ option.name }}</option>
                </select>
              </ng-container>

              <ng-container *ngSwitchCase="'subtitle'">
                <p id="ra{{field}}">{{ currentFormContent[i][field].content }}</p>
                <p>
                  <button (click)="readText(field)" aria-label="Wiedergabe">
                    <ion-icon name="play-circle"></ion-icon>
                  </button>
                </p>
              </ng-container>

              <ng-container *ngSwitchCase="'subtitlebold'">
                <p><b id="ra{{field}}">{{ currentFormContent[i][field].content }}</b></p>
                <p>
                  <button (click)="readText(field)" aria-label="Wiedergabe">
                    <ion-icon name="play-circle"></ion-icon>
                  </button>
                </p>
              </ng-container>

              <ng-container *ngSwitchCase="'checkboxthree'">
               <p>{{ currentFormContent[i][field].title }}</p>
                <div class="form-group">
                    <div class="form-group__checkbox" *ngFor="let input of currentFormContent[i][field].fields" >
                      <label for="{{ input.name }}">
                        <input type="checkbox" id="{{ input.name }}" name="{{ input.name }}" value="{{ input.value }}" (change)="checkedState($event, input.name)" [formControlName]="field">
                        {{input.value}}
                        <input type="text" *ngIf="input.textinput" value="" (keyup)="checkValue($event, input.name)">
                      </label>
                    </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'radio'">
                <h4>{{ currentFormContent[i][field].title }}</h4>
                 <div class="form-group--flex">
                    <div class="form-group__radio--flex" *ngFor="let input of currentFormContent[i][field].inputs; let i = index">
                  <label for="{{ field + i }}">
                    <span *ngIf="input.textinput; else elseBlock">
                       <input type="radio" id="{{ field + i }}" name="{{ field }}" value="{{ input.value }}" [id]="field + i" [formControlName]="field" (show)="checkTextInput($event, field + i + 'text')" >
                      {{input.label}}
                      <input type="text" value="" id="{{ field + i + 'text'}}"  [formControlName]="field" (keyup)="checkValue($event, field + i)">
                    </span>
                    <ng-template #elseBlock>
                      <input type="radio" id="{{ field + i }}" name="{{ field }}" value="{{ input.value }}" [id]="field + i" [formControlName]="field">
                      {{input.label}}
                    </ng-template>
                  </label>
                  </div>
                 </div>
              </ng-container>

              <ng-container *ngSwitchCase="'radioyes'">
                <h4>{{ currentFormContent[i][field].title }}</h4>
                <div class="form-group--flex">
                  <div class="form-group__radio--flex">
                    <label for="{{ field + '1' }}">

                       <input type="radio" id="{{ field + '1' }}" name="{{ field }}" value="yes" [id]="field + '1'" [formControlName]="field" (change)="addRequired($event, field + 'text')" >
                      Ja

                    </label>
                  </div>
                  <div class="form-group__radio--flex">
                    <label for="{{ field + '2' }}">
                      <input type="radio" id="{{ field + '2' }}" name="{{ field }}" value="no" [id]="field + '2'" [formControlName]="field" (change)="removeRequired($event, field + 'text')">
                     Nein </label>
                  </div>

                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'radiono'">
                <h4>{{ currentFormContent[i][field].title }}</h4>
                <div class="form-group--flex">
                  <div class="form-group__radio--flex">
                    <label for="{{ field + '1' }}">

                      <input type="radio" id="{{ field + '1' }}" name="{{ field }}" value="yes" [id]="field + '1'" [formControlName]="field" (change)="removeRequired($event, field + 'text')" >
                      Ja

                    </label>
                  </div>
                  <div class="form-group__radio--flex">
                    <label for="{{ field + '2' }}">
                      <input type="radio" id="{{ field + '2' }}" name="{{ field }}" value="no" [id]="field + '2'" [formControlName]="field" (change)="addRequired($event, field + 'text')">
                      Nein </label>
                  </div>

                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'textfieldyes'">
                <div class="form-group">
                  <p> {{ currentFormContent[i][field].title }}</p>
                  <input type="text" [id]="field" [formControlName]="field" >
                </div>

              </ng-container>

              <ng-container *ngSwitchCase="'textfieldno'">
                <div class="form-group">
                  <p> {{ currentFormContent[i][field].title }}</p>
                  <input type="text" [id]="field" [formControlName]="field" >
                </div>

              </ng-container>

              <ng-container *ngSwitchCase="'checkbox'">
                <div class="form-group">
                  <div class="form-group__check">
                  <label for="{{ field }}">
                    <input type="checkbox" id="{{ field }}" class="cbgroup" value="{{ currentFormContent[i][field].value }}" [value]="currentFormContent[i][field].value " [name]="field"  [formControlName]="field">
                    {{currentFormContent[i][field].value}}
                    <input type="text" *ngIf="currentFormContent[i][field].textinput" value=""  [formControlName]="field" (keyup)="checkValue($event, field)">
                  </label>
                </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="'checkboxGroup'">
                <div class="form-group">
                  <div class="form-group__check">
                    <label for="{{ field }}">
                      <input type="checkbox" id="{{ field }}" class="cbgroup" value="{{ currentFormContent[i][field].value }}" (change)="checkGroupCheckBox($event, currentFormContent[i][field].group)" [value]="currentFormContent[i][field].value " [name]="field"  [formControlName]="field">
                      {{currentFormContent[i][field].value}}
                      <p *ngIf="currentFormContent[i][field].textinput">{{currentFormContent[i][field].textvalue}}</p>
                      <input type="text" *ngIf="currentFormContent[i][field].textinput" value="" id="field"  [formControlName]="field" (keyup)="checkNonValue($event, field)">
                    </label>
                  </div>
                </div>
              </ng-container>


              <ng-container *ngSwitchDefault>
                <input
                  [type]="currentFormContent[i][field].type"
                  [formControlName]="field"
                  [id]="field"
                  [placeholder]="currentFormContent[i][field].placeholder"
                />
              </ng-container>
              <p
                class="validation-error"
                *ngIf="
                (masterForm[i].get(field).dirty ||
                  masterForm[i].get(field).touched) &&
                masterForm[i].get(field).invalid &&
                masterForm[i].get(field).errors
              "
              >
                {{ getValidationMessage(i, field) }}
              </p>
            </div>


          </div>
        </div>

        <div
          class="preview-container"
          *ngIf="activeStepIndex === stepItems.length - 1">
<!--          <ul>-->
<!--            <span-->
<!--              class="form-details-list"-->
<!--              *ngFor="let field of masterFormFields; index as i; trackBy: trackByFn">-->
<!--              <li *ngIf="formData[field]">-->
<!--                <span class="text-bold">{{ field | formatTitle }} {{formFields[i] | json }}</span>-->
<!--              <span>{{ formData[field] || 'N/A' }}</span>-->
<!--              </li>-->
<!--            </span>-->
<!--          </ul>-->
          <p>{{ 'Form.FullFill' | translate }}</p>
        </div>

        <div class="button-container">
<!--          <button-->
<!--            type="button"-->
<!--            class="button-large-wp"-->
<!--            [disabled]="!activeStepIndex"-->
<!--            (click)="goToStep('prev')"-->
<!--          >Zurück</button>-->
          <ion-button
            class="button-large-wp"
            *ngIf="activeStepIndex === stepItems.length - 1"
            type="button"
            (click)="onFormSubmit()"
          >{{ 'General.Submit' | translate }}</ion-button>
          <ion-button
            type="button"
            class="button-large-wp"
            *ngIf="activeStepIndex !== stepItems.length - 1"
            [disabled]="masterForm[i].invalid"
            (click)="goToStep('next');triggerScrollUp();"
          >{{ 'General.Next' | translate }}</ion-button>
        </div>
      </div>

    </form>
  </ng-container>
</div>
