import { Component, OnInit } from '@angular/core';
import { MenuService } from "../../services/menu/menu.service";
import { Storage } from "../../services/storage/storage";

@Component({
  selector: 'app-contrast-switcher',
  templateUrl: './contrast-switcher.component.html',
  styleUrls: ['./contrast-switcher.component.scss'],
  standalone: false
})
export class ContrastSwitcherComponent implements OnInit {

  readonly contrastNames = [
    {
      "code": "original",
      "label": "Original"
    }
  ];

  public selectContrast;

  constructor(
    private storage: Storage,
    private menu: MenuService
  ) {
    if (this.storage.getContrast()) {
      this.selectContrast = this.storage.getContrast();
    } else {
      this.selectContrast = 'original';
    }

    this.dynamicContrast();
  }

  ngOnInit() {}

  dynamicContrast() {
    this.menu.activeContrast(this.selectContrast);
  }

  async change(code: string) {
      this.menu.activeContrast(code)
    this.storage.setContrast(code);
  }

  trackByFn(index: number): number {
    return index;
  }

  upScale(e) {
    const root = document.documentElement;
    const currentSize = getComputedStyle(root).getPropertyValue('--font-size');
    const newSize = Number(currentSize) + 1;
    root.style.setProperty('--font-size', newSize.toString());
    e.preventDefault();
  }

  downScale(e) {
    const root = document.documentElement;
    const currentSize = getComputedStyle(root).getPropertyValue('--font-size');
    const newSize = Number(currentSize) - 1;
    root.style.setProperty('--font-size', newSize.toString());
    e.preventDefault();
  }

  scaleTo(fs: number) {
    const root = document.documentElement;
    root.style.setProperty('--font-size', fs.toString());
  }


}
