<ion-app>

  <ion-split-pane when="xs" contentId="main">

    <app-contrast-switcher></app-contrast-switcher>

    <ion-router-outlet id="main"></ion-router-outlet>

  </ion-split-pane>

</ion-app>

